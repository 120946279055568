var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-text',{staticClass:"pa-0",style:(`height: 300px; overflow: auto`)},[_c('a-table-f-api',{ref:"tableCash",attrs:{"api":_vm.api,"model":_vm.model,"searchable":false,"useQuery":false,"defaults":{
        sort: { key: 'id', order: 'DESC' },
        filters: {
          parent_name: _vm.m.accDocName,
          parent_id: _vm.data.id,
        },
        paramName: 'documentCash' + _vm.type,
      }},on:{"click":function($event){return _vm.onClickCash($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.canAdd)?_c('v-fab-transition',[_c('v-btn',{staticClass:"v-btn--example",staticStyle:{"bottom":"15px"},attrs:{"fab":"","dark":"","color":"green","absolute":"","bottom":"","small":"","left":""},on:{"click":function($event){_vm.showCashSelectDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e()]},proxy:true}])})],1),(_vm.showCashSelectDialog)?_c('select-dialog',{attrs:{"operations":_vm.m.operationsCash[_vm.type] || []},on:{"select":function($event){return _vm.onSelectOperation($event)}},model:{value:(_vm.showCashSelectDialog),callback:function ($$v) {_vm.showCashSelectDialog=$$v},expression:"showCashSelectDialog"}}):_vm._e(),_c('portal',{attrs:{"to":"v-main"}},[(_vm.cashType && _vm.showCashCreateDialog)?_c(_vm.cashType,{tag:"component",attrs:{"id":_vm.idEditCash,"config":_vm.configDoc,"document":_vm.data,"api":_vm.api},model:{value:(_vm.showCashCreateDialog),callback:function ($$v) {_vm.showCashCreateDialog=$$v},expression:"showCashCreateDialog"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }